import React from 'react'
import { Link } from 'react-router-dom'
// Components
import Footer from '../components/Footer'
import Header from '../components/Header'
// assets
import scales from '../assets/images/home/jadge-scales.png';
import hammer from '../assets/images/home/hammer.png'
import Integrity from '../assets/images/home/1.svg';
import Quality from '../assets/images/home/2.svg';
import Effect from '../assets/images/home/3.svg';
import Creative from '../assets/images/home/4.svg';
// animations
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

export default function Home() {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <Header />
      <Hero />
      <Services />
      <Values />
      <Footer />
    </>
  )
}
const Hero = () => {
  return (
    <section className='h-dvh max-w-7xl mx-auto px-2 xl:px-0 flex justify-between items-center relative w-full overflow-hidden' style={{ direction: 'rtl' }}>
      <div className='text-[#062841] flex flex-col justify-center gap-9 w-full xl:w-auto' data-aos='fade-left'>
        <h1 className='font-black text-3xl sm:text-4xl md:text-6xl'>مرحبًا بك في بصيرتك!</h1>
        <p className='max-w-[600px]'>نحن سعداء بانضمامك إلينا في رحلة تعليمية مبتكرة في مجال القانون، حيث نسعى لتحويل التعليم إلى تمكين.</p>
        <div className='flex w-fit items-center justify-center mx-auto xl:mx-0 xl:mr-auto'>
          <div className='relative z-10 flex w-full cursor-pointer items-center overflow-hidden rounded-full p-[2px]'>
            <div className='animate-rotate absolute inset-0 h-full rounded-full bg-[conic-gradient(#062841_20deg,transparent_120deg)]'></div>
            <Link
              to={'/trainee'}
              className='z-10 flex w-full items-center justify-center rounded-full bg-[#EDE4D6] hover:bg-[#EDE4D9] text-[#062841] font-extrabold text-2xl md:text-4xl px-10 py-5'
            >
              جــرب الان
            </Link>
          </div>
        </div>
      </div>
      <img src={scales} alt='scales img' className='hidden xl:inline-block size-[500px]' data-aos='fade-right' />
    </section>
  )
}
const Services = () => {
  return (
    <section className='min-h-screen max-w-7xl mx-auto px-2 xl:px-0 '>
      <div className=' w-full flex flex-col gap-3 justify-end items-end text-right' data-aos='fade-left'>
        <h2 className='text-[#062841] text-3xl sm:text-4xl md:text-6xl font-black border-b-2 border-[#062841] w-1/2 py-5 px-2'>ماذا نقدم </h2>
        <p className='text-[#49454F] text-xs md:text-2xl'>نقدم تدريب شامل لتعلم المهارات القانونية المختلفة بما في ذلك </p>
      </div>
      <div className='flex flex-col xl:flex-row gap-20 p-10 items-center justify-evenly w-full text-black flex-wrap text-center'>

        {/* card 1 */}
        <div className='group [perspective:1000px] text-black drop-shadow-xl aspect-square xl:size-64 w-full sm:aspect-auto sm:h-64' data-aos='zoom-in'>
          <div className='relative w-full h-full duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] grid place-items-center xl:p-5 px-5 py-20 '>
            {/* front */}
            <div className='absolute inset-0 bg-[#EDE4D6] text-black flex justify-center items-center rounded-[45px] [backface-visibility:hidden]'>
              <h3 className='text-3xl xl:text-2xl'>الترافع القضائي</h3>
            </div>
            <span className
              ='bg-[#DEA778] text-center rounded-full aspect-square size-14 text-white grid place-items-center text-lg absolute -top-3 -right-3 font-bold'>1</span>
            {/* back */}
            <div className='absolute inset-0 bg-[#EDE4D6] text-black flex justify-center items-center rounded-[45px] [backface-visibility:hidden] [transform:rotateY(180deg)] p-5'>
              <p>
                يتعلم فيها المتدرب مهارات الترافع والاعتراض على الأحكام أمام المحكمة الافتراضية, واتقان استراتيجيات الترافع
              </p>
            </div>
          </div>
        </div>
        <img src={hammer} alt='judge hammer' className='object-contain w-96 order-3' data-aos='zoom-in' />

        {/* card 3 */}
        <div className='group [perspective:1000px] text-black drop-shadow-xl aspect-square xl:size-64 w-full sm:aspect-auto sm:h-64 order-4 xl:order-3' data-aos='zoom-in'>
          <div className='relative w-full h-full duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] grid place-items-center xl:p-5 px-5 py-20 '>
            <div className='absolute inset-0 bg-[#EDE4D6] text-black flex justify-center items-center rounded-[45px] [backface-visibility:hidden]'>
              <h3 className='text-3xl xl:text-2xl'>مهارات التحليل والتكييف والاستدلال القانوني </h3>
            </div>
            <span className='bg-[#DEA778] text-center rounded-full aspect-square size-14 text-white grid place-items-center text-lg absolute -top-3 -right-3 font-bold'>3</span>
            <div className='absolute inset-0 bg-[#EDE4D6] text-black flex justify-center items-center rounded-[45px] [backface-visibility:hidden] [transform:rotateY(180deg)] p-5'>
              <p>
                يكتسب المتدرب مهارات استخلاص الوقائع، بناء الأدلة، تحديد القواعد القانونية، والتحليل والتكييف القانوني السليم.                         </p>
            </div>
          </div>
        </div>
        {/* card 2 */}
        <div className='group [perspective:1000px] text-black drop-shadow-xl aspect-square xl:size-64 w-full sm:aspect-auto sm:h-64 order-2 xl:order-4' data-aos='zoom-in'>
          <div className='relative w-full h-full duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] grid place-items-center xl:p-5 px-5 py-20 '>
            <div className='absolute inset-0 bg-[#EDE4D6] text-black flex justify-center items-center rounded-[45px] [backface-visibility:hidden]'>
              <h3 className='text-3xl xl:text-2xl p-3'>كتابة وصياغة المذكرات القانونية</h3>
            </div>
            <span className='bg-[#DEA778] text-center rounded-full aspect-square size-14 text-white grid place-items-center text-lg absolute -top-3 -right-3 font-bold'>2</span>
            <div className='absolute inset-0 bg-[#EDE4D6] text-black flex justify-center items-center rounded-[45px] [backface-visibility:hidden] [transform:rotateY(180deg)] p-5'>
              <p>
                يتعلم المتدرب أسس الصياغة القانونية، بما يشمل صياغة اللوائح والدعاوى والدفوع، ومراحل إعداد المذكرات القانونية وأساليب الكتابة القانونية.              </p>
            </div>
          </div>
        </div>

        {/* card 4 */}
        <div className='group [perspective:1000px] text-black drop-shadow-xl aspect-square xl:size-64 w-full sm:aspect-auto sm:h-64 order-5' data-aos='zoom-in'>
          <div className='relative w-full h-full duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] grid place-items-center xl:p-5 px-5 py-20 '>
            <div className='absolute inset-0 bg-[#EDE4D6] text-black flex justify-center items-center rounded-[45px] [backface-visibility:hidden]'>
              <h3 className='text-3xl xl:text-2xl'>مدارس الأنظمة القانونية </h3>
            </div>
            <span className='bg-[#DEA778] text-center rounded-full aspect-square size-14 text-white grid place-items-center text-lg absolute -top-3 -right-3 font-bold'>4</span>
            <div className='absolute inset-0 bg-[#EDE4D6] text-black flex justify-center items-center rounded-[45px] [backface-visibility:hidden] [transform:rotateY(180deg)] p-5'>
              <p>
                تمكين المتدرب من فهم المواد القانونية والالمام بها في كافة الأنظمة بأسلوب فعال.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const Values = () => {
  return (
    <section className='max-w-7xl mx-auto px-2 xl:px-0'>
      <h2 className='text-[#062841] text-3xl sm:text-4xl md:text-6xl font-black border-b-2 border-[#062841] w-1/2 py-5 px-2 text-right ml-auto  ' data-aos='fade-left'>قيمنا </h2>
      <div className='flex flex-col sm:grid sm:grid-cols-2 lg:grid-cols-4 xl:grid gap-10 p-10 items-center justify-evenly w-full text-black font-bold text-center'>

        <div className="group bg-[#3D5C70] text-[#EDE4D6] px-5 py-10 flex flex-col justify-center items-center gap-5 rounded-3xl h-full relative overflow-hidden" data-aos="zoom-in">
          <img src={Integrity} alt='Integrity icon' className="h-64 transition-transform duration-500 ease-in-out group-hover:translate-y-[-150%]" />
          <h3 className='group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-500 ease-in-out translate-y-3 opacity-70'>النزاهة والشفافية</h3>
          <p className="absolute top-5 left-5 right-5 opacity-0 group-hover:opacity-100 translate-y-96 group-hover:translate-y-1/2 transition-all duration-500 ease-in-out">
            نولي اهتمام في ترسيخ القيم والمبادئ في كل ما نقوم به، ونسعى لأن تكون النزاهة تجسيدا لكل أعمالنا ونتطلع لتحقيق التميز والاحترافية في خدماتنا وأعمالنا
          </p>
        </div>

        <div className="group bg-[#3D5C70] text-[#EDE4D6] px-5 py-10 flex flex-col justify-center items-center gap-5 rounded-3xl h-full relative overflow-hidden" data-aos="zoom-in">
          <img src={Quality} alt='Quality icon' className="h-64 transition-transform duration-500 ease-in-out group-hover:translate-y-[-150%]" />
          <h3 className='group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-500 ease-in-out translate-y-3 opacity-70'>الجودة والكفاءة </h3>
          <p className="absolute top-5 left-5 right-5 opacity-0 group-hover:opacity-100 translate-y-96 group-hover:translate-y-1/2 transition-all duration-500 ease-in-out">
            نحن نتحرى الجودة في كل ما نقوم به، ونسعى لترسيخ ثقافتها في عملنا لضمان التفوق والتميز في كل جوانب خدماتنا التدريبية بإشراف دقيق من فريق مختص
          </p>
        </div>

        <div className="group bg-[#3D5C70] text-[#EDE4D6] px-5 py-10 flex flex-col justify-center items-center gap-5 rounded-3xl h-full relative overflow-hidden" data-aos="zoom-in">
          <img src={Effect} alt='Effect icon' className="h-64 transition-transform duration-500 ease-in-out group-hover:translate-y-[-150%]" />
          <h3 className='group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-500 ease-in-out translate-y-3 opacity-70'>التـأثيـر</h3>
          <p className="absolute top-5 left-5 right-5 opacity-0 group-hover:opacity-100 translate-y-96 group-hover:translate-y-1/2 transition-all duration-500 ease-in-out">
            التزامنا هو احداث تاثير ملموس وقابل للقياس في المجتمع القانوني، ان كل خطوة مبتكرة هي لإحداث تغيير هدف من شأنها يضمن تحقيق تقدم فوري من خلال أساليبنا المتنوعة
          </p>
        </div>

        <div className="group bg-[#3D5C70] text-[#EDE4D6] px-5 py-10 flex flex-col justify-center items-center gap-5 rounded-3xl h-full relative overflow-hidden" data-aos="zoom-in">
          <img src={Creative} alt='Creative icon' className="h-64 transition-transform duration-500 ease-in-out group-hover:translate-y-[-150%]" />
          <h3 className="group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-500 ease-in-out translate-y-3 opacity-70">الابتكار</h3>
          <p className="absolute top-5 left-5 right-5 opacity-0 group-hover:opacity-100 translate-y-96 group-hover:translate-y-1/4 transition-all duration-500 ease-in-out">
            نلتزم بإعادة تعريف مفهوم التدريب العملي القانوني من خلال استخدام المحكمة الافتراضية المتطورة وأدوات الذكاء الصناعي، حيث يمكنهم التعلم من أخطائهم وتحسين مهاراتهم في بيئة محاكية للواقع
          </p>
        </div>

      </div>
    </section>
  )
}
