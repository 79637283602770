import React from 'react'
import { Link } from 'react-router-dom'

// Icons
import { FiLinkedin as Linkedin } from 'react-icons/fi';
import { FaXTwitter as XIcon } from 'react-icons/fa6';
import { MdOutlineAlternateEmail as Email } from 'react-icons/md';
import { RiTiktokLine as Tiktok } from 'react-icons/ri';

export default function Footer() {
    const linkStyle = 'group relative w-fit'
    const spanStyle = 'absolute bottom-0 left-1/2 w-0 h-px bg-white transition-all duration-50 ease-out group-hover:left-0 group-hover:w-full'
    return (
        <footer className='bg-[#3D5C70] w-screen p-5 text-white' style={{ direction: 'rtl' }}>
            <div className='max-w-7xl mx-auto px-2 xl:px-0 flex md:px-10 flex-col md:flex-row md:justify-between md:border-b-2 border-white pb-5'>
                <div className='flex flex-col md:flex-row md:items-center gap-4 border-b-2 md:border-0 py-5 md:py-0 w-full mx-auto'>
                    <a href='https://www.tiktok.com/@baseratk' className='flex items-center gap-3 group'>
                        <div className='grid place-items-center bg-[#EDE4D6] rounded-full p-1 transition-transform duration-150 ease-in-out group-hover:scale-[130%]'>
                            <Tiktok className='size-4 text-[#062841]' />
                        </div>
                        <p>تك توك</p>
                    </a>
                    <a href='https://www.linkedin.com/in/baseratk-sa-689709326' className='flex items-center gap-3 group'>
                        <div className='grid place-items-center bg-[#EDE4D6] rounded-full p-1 transition-transform duration-150 ease-in-out group-hover:scale-[130%]'>
                            <Linkedin className='size-4 text-[#062841]' />
                        </div>
                        <p>لينكدإن</p>
                    </a>
                    <a href='https://x.com/baseratk_sa' className='flex items-center gap-3 group'>
                        <div className='grid place-items-center bg-[#EDE4D6] rounded-full p-1 transition-transform duration-150 ease-in-out group-hover:scale-[130%]'>
                            <XIcon className='size-4 text-[#062841]' />
                        </div>
                        <p>إكس</p>
                    </a>
                    <a href='mailto:Baseratk.sa@gmail.com' className='flex items-center gap-3 group'>
                        <div className='grid place-items-center bg-[#EDE4D6] rounded-full p-1 transition-transform duration-150 ease-in-out group-hover:scale-[130%]'>
                            <Email className='size-4 text-[#062841]' />
                        </div>
                        <p>إيميل</p>
                    </a>
                </div>
            </div>
            <p className='mx-auto w-fit p-3 text-center'>&copy; {new Date().getFullYear()} بصيرتك - لأن المستقبل يبدأ ببصيرة واضحة. <span className='whitespace-nowrap'>جميع الحقوق محفوظة.</span></p>
        </footer>
    )
}
