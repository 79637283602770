import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// assets
import logo from '../assets/images/logo-text.png';
import { CgClose as CloseIcon } from 'react-icons/cg';
import { TbMenuDeep as MenuIcon } from 'react-icons/tb';
import { useAuth } from '../contexts/authContext';

export default function Header() {
    const { user, logoutUser } = useAuth();
    const menuRef = useRef(null);

    const [isLoggedin, setIsLoggedin] = useState(!!user);
    const [userType, setUserType] = useState(user?.role === 'lawyer' ? '/lawyer' : '/trainee');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className="h-16">
            <nav className="bg-[#3D5C70] text-[#EDE4D6] w-screen p-3 fixed flex items-center justify-between z-30">
                <Link
                    to="/"
                    className="bg-[#062841]/80 p-3 pr-10 rounded-br-full absolute left-0 top-0 h-[120%] max-w-40 flex items-center justify-center z-40"
                >
                    <img src={logo} alt="Baseratk" className="h-full object-contain" loading="lazy" />
                </Link>

                <div className="max-w-7xl mx-auto flex-1 px-2 xl:px-0 flex justify-between items-center">
                    <NavLarge isLoggedin={isLoggedin} user={user} logoutUser={logoutUser} />
                    <NavSmall isLoggedin={isLoggedin} user={user} logoutUser={logoutUser} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
                </div>
            </nav>
        </div>
    );
}

// Large Screen
const NavLarge = ({ isLoggedin, user, logoutUser }) => {
    const liStyle = 'group relative w-fit';
    const spanStyle = 'absolute bottom-0 left-1/2 w-0 h-px bg-[#EDE4D6] transition-all duration-300 ease-out group-hover:left-0 group-hover:w-full';

    return (
        <ul className="gap-8 text-lg hidden lg:flex">
            {isLoggedin ? (
                <>
                    <li className={liStyle}>
                        <p>مرحباً: {user?.name}</p>
                    </li>
                    <li className={liStyle}>
                        <button
                            onClick={logoutUser}
                            className="text-red-300 hover:text-red-400 transition-all"
                        >
                            تسجيل الخروج
                        </button>
                    </li>
                </>
            ) : (
                <>
                    <li className={liStyle}>
                        <Link to="/login">
                            <p>تسجيل الدخول</p>
                        </Link>
                        <span className={spanStyle}></span>
                    </li>
                    <li className={liStyle}>
                        <Link to="/register">
                            <p>إنشاء حساب</p>
                        </Link>
                        <span className={spanStyle}></span>
                    </li>
                </>
            )}
            <li className={liStyle}>
                {user &&
                    <Link to={user?.role == 'trainee' || user?.role == 'lawyer' ? `/${user.role}` : '/'}>
                        <p>المحتوى</p>
                    </Link>}
                <span className={spanStyle}></span>
            </li>
        </ul>
    );
};

// Small Screen
const NavSmall = ({ isLoggedin, user, logoutUser, isMenuOpen, setIsMenuOpen }) => {
    console.log("from navSmall:", !!user);
    return (
        <div className="lg:hidden">
            <button
                aria-label={isMenuOpen ? 'Close Menu' : 'Open Menu'}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="grid place-items-center h-7"
            >
                <CloseIcon
                    className={`absolute top-1/2 -translate-y-1/2 size-9 right-0 transition-all transform duration-300 ${isMenuOpen ? 'opacity-100 rotate-0' : 'opacity-0 rotate-180'
                        }`}
                />
                <MenuIcon
                    className={`absolute top-1/2 -translate-y-1/2 size-9 right-0 transition-all transform duration-300 ${!isMenuOpen ? 'opacity-100 rotate-0' : 'opacity-0 -rotate-180'
                        }`}
                />
            </button>

            {isMenuOpen && (
                <>
                    <span
                        className="fixed top-12 left-0 w-screen h-screen bg-gray-200/50 z-20"
                        onClick={() => setIsMenuOpen(false)}
                    ></span>
                    <ul className="absolute top-12 left-0 bg-[#3D5C70] w-full flex flex-col items-center gap-4 text-lg p-4 z-30">
                        {isLoggedin ? (
                            <>
                                <li>
                                    <p>مرحباً: {user?.name}</p>
                                </li>
                                <li>
                                    <button
                                        onClick={() => {
                                            setIsMenuOpen(false);
                                            logoutUser();
                                        }}
                                        className="text-red-300 hover:text-red-400 transition-all"
                                    >
                                        تسجيل الخروج
                                    </button>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <Link to="/login" onClick={() => setIsMenuOpen(false)}>
                                        تسجيل الدخول
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/register" onClick={() => setIsMenuOpen(false)}>
                                        إنشاء حساب
                                    </Link>
                                </li>
                            </>
                        )}
                        <li>
                            {user &&
                                <Link to={user?.role == 'trainee' || user?.role == 'lawyer' ? `/${user.role}` : '/'} onClick={() => setIsMenuOpen(false)}>
                                    <p>المحتوى</p>
                                </Link>}
                        </li>
                    </ul>
                </>
            )}
        </div>
    );
};
